export type CardIdType = string | number

/**
 * Supported `/pages` paths values (as in `router.asPath`).
 * Use value from this object when you want to navigate users to a specific page.
 * Effectively, this the purpose of this is to:
 * 1. move away from using magic values;
 * 2. eliminate building template strings in-place inside components.
 */
export const PATHS = {
  INDEX: '/',
  APPLICATION_PERSONAL_INFO: (type: string) =>
    `/application/${type}/personal-info`,
  APPLICATION_SECONDARY_EMPLOYEE: '/application/employee',
  APPLICATION_VERIFICATION: `/application/verification`,
  APPLICATION_DOCUMENTS_UPLOAD: `/application/manual-review`,
  APPLICATION_COMMERCIAL_PERSONAL_INFO: `/application/commercial/personal-info`,
  APPLICATION_COMMERCIAL_BUSINESS_INFO: `/application/commercial/business-info`,
  APPLICATION_COMMERCIAL_TERMS_AND_CONDITIONS: `/application/commercial/terms-and-conditions`,
  APPLICATION_COMMERCIAL_OFFER: `/application/commercial/offer`,
  APPLICATION_COMMERCIAL_DECLINED: `/application/commercial/declined`,
  APPLICATION_CONSUMER_PERSONAL_INFO: `/application/consumer/personal-info`,
  APPLICATION_CONSUMER_PREQUALIFICATION_TERMS: `/application/consumer/pre-qualification/terms`,
  APPLICATION_CONSUMER_OFFER: `/application/consumer/offer`,
  APPLICATION_CONSUMER_DECLINED: `/application/consumer/declined`,
  MANAGEMENT_CARD_ID: (cardId: CardIdType) => `/management/${cardId}`,
  MANAGEMENT_CARD_ID_PAYMENT_METHODS: (cardId: CardIdType) =>
    `/management/${cardId}/payment-methods`,
  MANAGEMENT_CARD_ID_PAYMENT: (cardId: CardIdType) =>
    `/management/${cardId}/payment`,
  MANAGEMENT_CARD_ID_PAYMENT_AUTOPAY: (cardId: CardIdType) =>
    `/management/${cardId}/payment/autopay`,
  MANAGEMENT_CARD_ID_PAYMENT_AUTOPAY_DISABLE: (cardId: CardIdType) =>
    `/management/${cardId}/payment/autopay-disable`,
  MANAGEMENT_CARD_ID_REWARDS: (cardId: CardIdType) =>
    `/management/${cardId}/rewards`,
  MANAGEMENT_CARD_ID_REPLACE: (cardId: CardIdType) =>
    `/management/${cardId}/replace`,
  MANAGEMENT_OAUTH_REDIRECT: '/management/oauth-redirect',
  MANAGEMENT_CARD_ID_TRANSACTIONS: (cardId: CardIdType) =>
    `/management/${cardId}/transactions`,
  MANAGEMENT_CARD_ID_STATEMENTS: (cardId: CardIdType) =>
    `/management/${cardId}/statements`,
  MANAGEMENT_CARD_ID_ACTIVATE: (cardId: CardIdType) =>
    `/management/${cardId}/activate`,
  MANAGEMENT_CARD_ID_PROFILE: (cardId: CardIdType) =>
    `/management/${cardId}/profile`,
  MANAGEMENT_CARD_ID_SCHEDULED_PAYMENTS: (cardId: CardIdType) =>
    `/management/${cardId}/scheduled-payments`,
  MANAGEMENT_CARD_ID_LOCK_UNLOCK: (cardId: CardIdType) =>
    `/management/${cardId}/lock-unlock`,
  MANAGEMENT_CARD_ID_COMMERCIAL_LEGAL_AND_PRIVACY: (cardId: CardIdType) =>
    `/management/${cardId}/commercial/legal-privacy`,
  MANAGEMENT_CARD_ID_CONSUMER_LEGAL_AND_PRIVACY: (cardId: CardIdType) =>
    `/management/${cardId}/consumer/legal-privacy`,
  MANAGEMENT_CARD_ID_SECONDARY_CARD_USERS: (cardId: CardIdType) =>
    `/management/${cardId}/secondary-card-users`,
  MANAGEMENT_CARD_ID_DOCUMENTS: (cardId: CardIdType) =>
    `/management/${cardId}/documents`,
  MANAGEMENT_CARD_ID_WELCOME: (cardId: CardIdType) =>
    `/management/${cardId}/welcome`,
  SESSION_INVALID: `/session/invalid`,
  SESSION_MISSING: `/session/missing`,
  NOT_FOUND: '/not-found',
}

export const ADMIN_REQUIRED_PATHS = [
  '/payment',
  '/scheduled-payments',
  '/payment-methods',
  '/secondary-card-users',
  '/statements',
]

export const COMMERCIAL_REQUIRED_PATHS = [
  PATHS.APPLICATION_COMMERCIAL_PERSONAL_INFO,
  PATHS.APPLICATION_COMMERCIAL_BUSINESS_INFO,
  PATHS.APPLICATION_COMMERCIAL_TERMS_AND_CONDITIONS,
  PATHS.APPLICATION_COMMERCIAL_OFFER,
  PATHS.APPLICATION_COMMERCIAL_DECLINED,
  '/secondary-card-users',
  '/commercial/legal-privacy',
]

export const CONSUMER_REQUIRED_PATHS = [
  PATHS.APPLICATION_CONSUMER_PERSONAL_INFO,
  PATHS.APPLICATION_CONSUMER_PREQUALIFICATION_TERMS,
  PATHS.APPLICATION_CONSUMER_DECLINED,
  PATHS.APPLICATION_CONSUMER_OFFER,
  '/consumer/legal-privacy',
]
